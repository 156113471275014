import React, { useState, useEffect } from 'react';
import { useWixSdk, useEnvironment, useBi } from '@wix/yoshi-flow-editor';
import './wixStyles.global.scss';
import Gog from './GogOOI/Gog';
import { useStyles } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { gogLoaded } from '@wix/bi-logger-photo-ugc/v2';
import { sortByEnum } from '../../../services/albumsSorter';
import stylesParams from '../stylesParams';
import { VIEW_MODES } from '../../../consts/generalConsts';
import { pauseHeightChanges } from '@wix/yoshi-flow-editor/wix-height-updater';

interface WidgetWrapperProps {
  visitorId: string;
  userOwnership: string;
  appName: string;
  mobile: boolean;
  language: string;
  translations: Record<string, string>;
  host: any;
  instance: string;
  instanceId: string;
  biToken: string;
  updateLayout: any;
  viewMode: string;
  setHeight: any;
  albumsCovers: GalleryItem[];
  staticMediaUrls: any;
  coverIdToAlbum: { [id: string]: GogAlbum };
  gogAlbums: GogAlbum[];
  fetchAlbums: () => Promise<{
    gogData: any;
    albumsCovers: GalleryItem[];
    coverIdToAlbum: {
      [id: string]: GogAlbum;
    };
  }>;
  isSSR?: boolean;
  experiments?: any;
  updateAlbumCovers: (gogAlbums: GogAlbum[], sortBy: number) => void;
  updateGoGAlbums: (gogAlbums: GogAlbum[], sortBy: number) => void;
}

export default (props: WidgetWrapperProps) => {
  const {
    visitorId,
    userOwnership,
    host,
    instanceId,
    biToken,
    viewMode,
    updateGoGAlbums,
    updateAlbumCovers,
  } = props;
  // Fix For Editor Preview Mode
  // Need it only when the GoG is set to stretch
  if (host.dimensions.width === '100%') {
    host.dimensions.width = '';
  }

  const { isSSR } = useEnvironment();
  const { Wix } = useWixSdk();
  const styles = useStyles();
  const biLogger = useBi();
  const sortByKey = styles.get(stylesParams.sortBy) || 0;

  const handleWixEvent = async (event: any) => {
    if (event.action === 'albumsData') {
      try {
        const shouldDisplayDefaultAlbums = event.data.every(
          (item: any) => item === false,
        );
        let gogAlbums: GogAlbum[] = event.data;
        if (shouldDisplayDefaultAlbums) {
          gogAlbums = [];
        }
        const sortBySP = styles.get(stylesParams.sortBy);
        updateGoGAlbums(gogAlbums, sortBySP);
      } catch (e: any) {
        console.error('Failed to update gog ' + e);
      }
    }
  };

  // sort albums
  useEffect(() => {
    if (viewMode === VIEW_MODES.EDITOR && props.gogAlbums.length > 0) {
      updateAlbumCovers(props.gogAlbums, sortByKey);
    }
  }, [sortByKey]);

  useEffect(() => {
    if (viewMode === VIEW_MODES.EDITOR) {
      pauseHeightChanges();
      Wix && Wix.addEventListener(Wix.Events.SETTINGS_UPDATED, handleWixEvent);
    }
    biLogger.report(
      gogLoaded({
        biToken,
        instance_id: instanceId,
        is_editor: viewMode === VIEW_MODES.EDITOR,
        is_owner: userOwnership === 'Admin',
        visitor_id: visitorId,
      }),
    );
  }, []);

  const styleParams = host.style.styleParams;

  const gogProps = {
    ...props,
    pageId: host.pageId,
    compId: host.id,
    dimensions: host.dimensions,
    styleParams,
    textPresets: host.style.siteTextPresets,
    albumsCovers: props.albumsCovers,
    coverIdToAlbum: props.coverIdToAlbum,
    formFactor: host.formFactor,
    staticMediaUrls: props.staticMediaUrls,
    isSSR,
    Wix,
  };

  return (
    <div className="albums-gog-wrapper" data-hook="AlbumsGOGOOI-wrapper">
      <Gog {...gogProps} />
    </div>
  );
};
