import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import _ from 'lodash';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { useWixSdk } from '@wix/yoshi-flow-editor';
import { WixProGallery } from '@wix/pro-gallery-wix-wrapper';
import { getProGalleryStyles } from '@wix/photography-client-lib';
import { parseViewMode } from '../../../../services/galleryHelper';
import {
  defaultPGStyleParams,
  gogNewVersionDefaultsStyleParams,
  gogSpecificGalleryStyleParams,
} from '../../../../services/styleParams/defaultStyleParams';
import AlbumInfo from './AlbumInfo';

const RESIZE_OBSERVER_DELAY = 300;
type WixProGalleryWrapperType = {
  items: GalleryItem[];
  staticMediaUrls: any;
  styleParams: any;
  viewMode: any;
  compId: string;
  isMobile: boolean;
  textPresets: any;
  t: (key: string) => string;
  coverIdToAlbum: { [id: string]: GogAlbum };
  onAlbumClick: (album: any) => void;
  dimensions: { width: number; height?: number };
};
const ProGalleryWixWrapper = (props: WixProGalleryWrapperType) => {
  const { items, staticMediaUrls, styleParams, isMobile, compId } = props;
  const defaultContainer = {
    scrollBase: 0,
    width: props.dimensions.width || 1000,
    height: props.dimensions.height || 1000,
    isDefaultContainer: true,
  };
  const containerRef = useRef<any>(null);
  const [container, setContainer] = useState<any>(defaultContainer);
  const { Wix } = useWixSdk();
  const handleHeightChanges = useCallback(
    _.debounce((height: number, width?: number) => {
      Wix && Wix.setHeight(height);
      setContainer((prev: any) => {
        const containerHeight = height ? height : prev.hight;
        const containerWidth = width ? width : prev.width;
        return { ...prev, height: containerHeight, width: containerWidth };
      });
    }, 500),
    [],
  );

  // ********************************************************************************
  const fonts = props.styleParams.fonts;
  if (fonts) {
    for (const fontKey in fonts) {
      if (
        fonts[fontKey] &&
        typeof fonts[fontKey] === 'object' &&
        !fonts[fontKey].value
      ) {
        fonts[fontKey].value = '';
      }
    }
  }
  // Style parmas parser
  const isFirstAndInstallFromMarket = (currentSP: any) => {
    if (
      currentSP &&
      Object.keys(currentSP.booleans).length < 2 &&
      Object.keys(currentSP.numbers).length === 0 &&
      Object.keys(currentSP.fonts).length === 0 &&
      Object.keys(currentSP.colors).length === 0
    ) {
      return true;
    }

    return false;
  };
  let InfoElement;
  const firstAndInstallFromMarket = isFirstAndInstallFromMarket(styleParams);
  const allowProGalleryStyleParams =
    firstAndInstallFromMarket ||
    (styleParams.booleans && styleParams.booleans.isProGalleryGoG);

  let galleryStyleParams: any;

  if (allowProGalleryStyleParams) {
    const options = {isMobile};
    if (firstAndInstallFromMarket) {
      galleryStyleParams = {
        // convert editor styles to pg styles
        ...getProGalleryStyles(gogNewVersionDefaultsStyleParams, options),
      };
    } else {
      galleryStyleParams = {
        // convert editor styles to pg styles
        ...getProGalleryStyles(styleParams, options),
      };
    }

    // ensure pg social styles are set to false
    galleryStyleParams = {
      ...galleryStyleParams,
      ...gogSpecificGalleryStyleParams,
    };
  } else {
    InfoElement = (itemProps: ItemProps) => (
      <AlbumInfo
        key={itemProps.id}
        dimensions={{ width: itemProps.style.width }}
        data={props.coverIdToAlbum[itemProps.id]}
        styleParams={galleryStyleParams}
        textPresets={props.textPresets}
        t={props.t}
      />
    );
  }

  // ********************************************************************************
  const eventsListener = useCallback((eventName: string, eventData: any) => {
    switch (eventName) {
      case 'ITEM_CLICKED':
        props.onAlbumClick(props.coverIdToAlbum[eventData.id]);
        break;
      case 'GALLERY_CHANGE':
        console.log(eventData);
        break;
      default:
        break;
    }
  }, []);

  const resizeGallery = useCallback(() => {
    setContainer({
      width: containerRef.current?.clientWidth,
      height: containerRef.current?.clientHeight,
      isDefaultContainer: false,
    });
  }, []);

  const resizeObserverCallback = useCallback(() => {
    _.debounce(resizeGallery, RESIZE_OBSERVER_DELAY)();
  }, [resizeGallery]);

  useResizeObserver({
    ref: containerRef,
    callback: resizeObserverCallback,
  });

  const viewMode = parseViewMode(props.viewMode);

  return (
    <div id="lists-gallery-container" style={{ height: container.height }}>
      <div ref={containerRef} style={{ height: '100%' }}>
        <WixProGallery
          id={compId}
          deviceType={isMobile ? 'mobile' : 'desktop'}
          isMobile={isMobile}
          staticMediaUrls={staticMediaUrls}
          items={items}
          styles={{
            ...defaultPGStyleParams,
            ...galleryStyleParams,
          }}
          container={container}
          eventsListener={eventsListener}
          scrollingElement={containerRef.current}
          viewMode={viewMode}
          setLayoutHeightImp={handleHeightChanges}
          customInfoRenderer={InfoElement}
          experimentalFeatures={{}} // FIXME: why is this mandatory?
        />
      </div>
    </div>
  );
};

export default ProGalleryWixWrapper;
